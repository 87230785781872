<template>
  <div id="manager">
    <list-template 
      v-bind="config" 
      @fetch:data="fetchData"
      @delete:confirm="deleteConfirm"
    />
  </div>
</template>

<script>
// mixins
import crudRequestMixin from '@/mixins/listPages/httpRequestMixins';
// components
import ListTemplate from "@/components/templates/ListTemplate.vue";

export default {
  name: 'ManagerList',
  mixins: [
    crudRequestMixin
  ],
  components: {
    ListTemplate,
  },
  data: () => {
    return {
      config: {
        loading: false,
        title: 'Data Manager',
        pagination: {},
        rows: [],
        columns: [
          {
            key: "firstName",
            label: "First Name",
          },
          {
            key: "lastName",
            label: "Last Name",
          },
          {
            key: "email",
            label: "Email",
          },
          {
            key: 'phoneNumber',
            label: "Phone Number"
          },
          {
            key: 'mobileNumber',
            label: 'Mobile Number',
          },
          {
            key: 'actions',
            label: 'Actions'
          },
        ],
        loadingText: 'Fetching Data...',
        emptyText: 'No data found',
        createFormName: "CreateManager",
        createButtonText: "Add Manager",
        editFormName: 'EditManager',
        searchPlaceholder: 'Search by First Name',
        searchBy: 'firstName'
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleFetchListSuccess(response) {
      this.config.rows = response.data.content;
      this.config.pagination = response.data.pageable;
      this.config.pagination['totalRows'] = response.data.totalElements;
      this.config.pagination['totalPage'] = response.data.totalPages; 

      this.config.rows.forEach(el => {
        el['details'] = [
          { key: 'workplaceName', label: 'Department of the Hospital', value: el.workplaceName },
          { key: 'language', label: 'Language', value: el.language },
          { key: 'city', label: 'City', value: el.city },
          { key: 'postcode', label: 'Postcode', value: el.postcode }
        ];
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
#manager {
  height: 60vh !important;
}
</style>
