<template>
  <div id="second-opinion">
    <list-template
      v-bind="config"
      @fetch:data="fetchData"
      @delete:confirm="deleteConfirm"
    >
      <template #create-section>
        <a
          v-if="userData.role === 'PHYSICIAN'"
          href="javascript:void(0)"
          class="mr-3 mt-2 anchor-link"
          style="font-size: 13px"
          @click="openSecondOpinionModal"
        >
          Add Second Opinion</a
        >
      </template>

      <template #actions="{ row }">
        <dropdown-table-action 
          :items="config.tableAction"
          :row="row"
        />
      </template>
    </list-template>

    <modal-template
      v-bind="config.modal.opinion"
      @modal:on-close="closeSecondOpinionModal"
    >
      <template #modal-body>
        <b-form-group v-if="!selectedPatient">
          <b-form-select
            v-model="selectedPatient"
            :options="patientList"
            type="text"
            name="patient"
            placeholder="Select Patient"
            size="sm"
          />
        </b-form-group>

        <table v-if="selectedPatient" style="font-size: 14px">
          <tr>
            <td>Patient ID</td>
            <td>:</td>
            <td>{{ selectedPatient }}</td>
          </tr>
          <tr>
            <td>Physician</td>
            <td>:</td>
            <td>{{ userData.lastName }}, {{ userData.firstName }}</td>
          </tr>
        </table>

        <br v-if="selectedPatient" />

        <b-form-group v-if="selectedPatient">
          <label> Patient's Second Opinion</label>
          <b-form-textarea
            v-model="secondOpinionText"
            id="opinion"
            name="opinion"
            class="form-control form-control-sm"
            placeholder="Second Opinion"
            size="sm"
            style="height: 200px"
          />
        </b-form-group>
      </template>

      <template #modal-footer-custom>
        <button
          :disabled="!secondOpinionText"
          class="btn btn-sm btn-pompe-primary"
          @click.prevent="submitOpinion"
        >
          {{ config.modal.opinion.state !== "edit" ? "Submit" : "Update" }}
        </button>
      </template>
    </modal-template>
  </div>
</template>

<script>
// mixins
import crudRequestMixin from "@/mixins/listPages/httpRequestMixins";
// components
import ListTemplate from "@/components/templates/ListTemplate.vue";
import ModalTemplate from "@/components/templates/ModalTemplate.vue";

export default {
  name: "PhysiciansList",
  mixins: [crudRequestMixin],
  components: {
    ListTemplate,
    ModalTemplate,
  },
  props: {
    userData: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    const _this = this;

    return {
      config: {
        modal: {
          opinion: {
            title: "Add Second Opinion",
            modalSize: "md",
            show: false,
            state: null,
          },
        },
        loading: false,
        title: "Patient Second Opinion",
        pagination: {},
        rows: [],
        columns: [
          {
            key: "patientId",
            label: "Patient ID",
          },
          {
            key: "physicianName",
            label: "Physician Name",
          },
          {
            key: "opinion",
            label: "Opinion",
          },
          {
            key: "actions",
            label: "Actions",
          },
        ],
        tableAction: [
          {
            key: 'edit',
            text: 'Edit',
            isDisabled: () => _this.userData.role !== 'PHYSICIAN',
            action: (row) => _this.editSecondOpinion(row.item)
          },
          {
            key: 'delete',
            text: 'Delete',
            isDisabled: () => _this.userData.role !== 'PHYSICIAN',
            action: (row) => _this.deleteConfirm(row.item)
          }
        ],
        loadingText: "Fetching Data...",
        emptyText: "No data found",
        searchPlaceholder: "Search by Patient ID",
        searchBy: "patientId",
      },
      selectedPatient: null,
      patientList: [],
      secondOpinionText: null,
      opinionData: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    openSecondOpinionModal() {
      this.config.modal.opinion.show = true;
      this.getRelatedPatients();
    },
    editSecondOpinion(item) {
      this.config.modal.opinion.state = "edit";
      this.config.modal.opinion.title = "Edit Second Opinion";
      this.config.modal.opinion.show = true;

      this.opinionData = item;
      this.selectedPatient = item.patientId;
      this.secondOpinionText = item.opinion;

      this.getRelatedPatients();
    },
    closeSecondOpinionModal() {
      this.config.modal.opinion.show = false;
      this.selectedPatient = this.secondOpinionText = this.opinionData = null;
    },
    getRelatedPatients() {
      const payload = {
        criteria: {
          field: "physiciansOpinionIds",
          op: "is",
          value: this.userData.id,
        },
      };

      this.$requestService.post(
        this.$apiEndpoints.summaryPatientView + "/search",
        payload,
        this.handleGetPatientSuccess
      );
    },
    handleGetPatientSuccess(response) {
      this.patientList = [
        { value: null, text: "Select Patient ID" },
        ...response.data.content.map((el) => el.id),
      ];
    },
    submitOpinion() {
      // config for post
      let method = "post";
      let url = this.$apiEndpoints.secondopinions;
      let payload = {
        patientId: this.selectedPatient,
        physicianId: this.userData.id,
        physicianName:
          `${this.userData.lastName}, ${this.userData.firstName}` ||
          this.opinionData.physicianName,
        opinion: this.secondOpinionText,
      };

      // config for edit
      if (this.config.modal.opinion.state === "edit") {
        method = "put";
        url = url + `/${this.opinionData.id}`;
        payload = {
          id: this.opinionData.id,
          creationDate: this.opinionData.creationDate,
          ...payload,
        };
      }

      this.$requestService[method](
        url,
        payload,
        this.handleOpinionSubmitSuccess
      );
    },
    handleOpinionSubmitSuccess() {
      this.closeSecondOpinionModal();
      this.fetchData();
    },
    handleFetchListSuccess(response) {
      this.config.rows = response.data.content;
      this.config.pagination = response.data.pageable;
      this.config.pagination["totalRows"] = response.data.totalElements;
      this.config.pagination["totalPage"] = response.data.totalPages;

      this.config.rows.forEach((el) => {
        el["details"] = [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
#second-opinion {
  height: 60vh !important;
}
</style>
