<template>
  <div id="list-template">
    <div class="row">
      <div v-if="title" class="col text-left">
        <h5 class="mt-1" style="margin: 0">{{ title }}</h5>
      </div>

      <div class="col-4"></div>

      <div class="col">
        <b-input-group>
          <slot name="create-section">
            <router-link
              v-if="createFormName"
              :to="{ name: createFormName }"
              class="mr-3 mt-2 anchor-link"
              style="font-size: 13px"
            >
              {{ createButtonText }}
            </router-link>
          </slot>
          <b-form-input
            v-if="searchable"
            v-model="searchvalue"
            size="sm"
            name="search"
            :placeholder="searchPlaceholder"
          ></b-form-input>
          <b-input-group-append v-if="searchable">
            <button
              class="btn btn-sm btn-pompe-secondary"
              @click.prevent="fetchData"
            >
              <b-icon class="mr-1" icon="search" aria-hidden="true"></b-icon>
            </button>
            <b-button
              v-if="searchvalue"
              size="sm"
              variant="danger"
              @click.prevent="resetSearch"
            >
              <b-icon
                class="mr-1"
                scale="2"
                icon="x"
                aria-hidden="true"
              ></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div class="table-container">
      <b-table
        :key="componentUpdate"
        class="mt-3"
        thead-class="th-label"
        tbody-class="tr-label"
        bordered
        striped
        :items="rows"
        :fields="columns"
        :busy="loadingState"
        :show-empty="!loadingState && !rows.length"
      >
        <template #empty>
          <div class="text-center">
            <span>{{ emptyText }}</span>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ loadingText }} </strong>
          </div>
        </template>

        <template #row-details="row">
          <slot name="details">
            <b-card>
              <b-row v-for="(item, idx) in row.item.details" :key="idx" class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>{{ item.label }}:</b></b-col>
                <b-col>{{ item.value }}</b-col>
              </b-row>

              <b-button size="sm" @click="toggleDetail(row.index)">Hide Details</b-button>
            </b-card>
          </slot>
        </template>

        <template #cell(actions)="row">
          <slot 
            name="actions" 
            :row="row"
          >
            <dropdown-table-action 
              :items="config.table.defaultTableAction"
              :row="row"
            />
          </slot>
        </template>
      </b-table>

      <div style="width='50px'">
        <b-pagination
          page-class="customPagination"
          v-model="config.pagination.currentPage"
          :perPage="config.pagination.perPage"
          :currentPage="config.pagination.currentPage"
          :totalRows="config.pagination.totalRows"
          align="right"
          size="sm"
          @input="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListTemplate",
  props: {
    // searchable
    searchable: {
      type: Boolean,
      default: true,
    },
    // page title
    title: {
      type: String,
      default: "",
    },
    // data to be displayed
    rows: {
      type: Array,
      default: () => [],
    },
    // columns to be displayed
    columns: {
      type: Array,
      default: () => [],
    },
    //pagination config
    pagination: {
      type: Object,
      default: () => {},
    },
    // loading state
    loading: {
      type: Boolean,
      default: false,
    },
    // empty data text
    emptyText: {
      type: String,
      default: "Data Empty",
    },
    // loading text
    loadingText: {
      type: String,
      default: "Loading",
    },
    // create form component name
    createFormName: {
      type: String,
      default: null,
    },
    // create button text
    createButtonText: {
      type: String,
      default: "Create",
    },
    // edit form component name
    editFormName: {
      type: String,
      required: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    // search parameter
    searchBy: {
      type: [String, Array],
      default: "name",
    },
    updateComponent: {
      type: Number,
      default: 1,
    }
  },
  data() {
    const _this = this;

    return {
      componentUpdate: 1,
      loadingState: false,
      searchvalue: "",
      items: [],
      config: {
        pagination: {
          perPage: 1,
          currentPage: 1,
          totalRows: 1,
        },
        table: {
          defaultTableAction: [
            {
              type: 'link',
              text: 'Edit',
              href: 'javascript:void(0)',
              class: 'anchor-link',
              icon: 'pencil',
              action: (row) => _this.$router.push({
                name: _this.editFormName,
                params: {
                  id: row.item.id
                }
              }),
              displayCondition: () => _this.editFormName
            },
            {
              type: 'link',
              text: 'Detail',
              href: 'javascript:void(0)',
              class: 'anchor-link',
              icon: 'eye',
              action: (row) => _this.toggleDetail(row.index),
              displayCondition: (row) => row.item.details && row.item.details.length
            },
            {
              type: 'link',
              text: 'Delete',
              href: 'javascript:void(0)',
              class: 'anchor-link',
              icon: 'trash',
              action: (row) => _this.$emit('delete:confirm', row.item)
            }
          ]
        }
      }
    }
  },
  watch: {
    loading: {
      handler(val) {
        this.loadingState = val;
      },
    },
    pagination: {
      handler(val) {
        this.config.pagination = {
          perPage: val.pageSize,
          currentPage: Number(val.pageNumber) + 1,
          totalRows: val.totalRows,
        };
      },
      deep: true,
      immediate: true,
    },
    updateComponent: {
      handler(val) {
        if (val) this.componentUpdate++;
      }
    } 
  },
  methods: {
    toggleDetail(idx) {
      this.rows[idx]['_showDetails'] 
        = this.rows[idx]['_showDetails'] 
          ? !this.rows[idx]['_showDetails']
          : true;

      this.componentUpdate++;
    },
    fetchData() {
      let searchCriteria = null;

      if (typeof this.searchBy !== 'string') {
        searchCriteria = {
          op: "or",
          of: [
            ...this.searchBy.map(el => ({
              field: el,
              op: 'contains',
              value: this.searchvalue
            }))
          ]
        }
      } else {
        searchCriteria = [
          {
            op: "is",
            field: this.searchBy,
            value: this.searchvalue,
          }, 
        ];
      }
      const payload = {
        criterias: searchCriteria,
        paging: {
          page: this.config.pagination.currentPage - 1,
          size: this.config.pagination.perPage,
        },
      };

      this.$emit("fetch:data", payload);
    },
    resetSearch() {
      this.searchvalue = null;
      this.$emit("fetch:data");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
</style>
