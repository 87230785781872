<template>
  <div id="second-opinion">
    <modal-template v-bind="config" @modal:on-close="$emit('action:close')">
      <template #modal-body>
        <notification
          type="info"
          message="Assign physician(s) that can see the data entry for a selected patient"
        />

        <b-form-group v-if="role === 'ADMIN'">
          <h6>Available Hospital</h6>
          <b-form-select
            v-model="selectedHospital"
            :options="hospitalList"
            type="text"
            name="hospital"
            placeholder="Select Hospital"
            size="sm"
          />
        </b-form-group>

        <div v-if="selectedHospital" class="row">
          <div class="col">
            <h6>Available Physicians</h6>

            <b-list-group>
              <b-list-group-item
                v-for="(item, idx) in availablePhysicianForOpinion"
                :key="idx"
                class="
                  custom-list-group
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <small> {{ item.text }} </small>
                <small>
                  <a
                    href="javascript:void(0)"
                    class="anchor-link"
                    @click.prevent="$emit('action:assign-physician', item)"
                  >
                    Add
                  </a>
                </small>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="col">
            <h6>Selected Physician For This Patient</h6>
            <span v-if="!physicianOpinionsList.length">
              <small class="text-muted">
                No Physician Assigned yet to this patient
              </small>
              <br />
              <small class="text-muted">
                Click "Add" on available physicians
              </small>
            </span>
            <b-list-group v-if="physicianOpinionsList.length">
              <b-list-group-item
                v-for="(item, idx) in physicianOpinionsList"
                :key="idx"
                class="
                  custom-list-group
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <small>{{ item.text }} </small>
                <small>
                  <a
                    href="javascript:void(0)"
                    class="anchor-link"
                    @click.prevent="$emit('action:remove-physician', item)"
                  >
                    Remove
                  </a>
                </small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
        <br v-if="selectedHospital" />
        <small class="text-muted" v-if="selectedHospital">
          Any changes on assigned physician will be saved automatically
        </small>
      </template>

      <template #modal-footer-custom>
        <button
          class="btn btn-pompe-primary"
          @click.prevent="$emit('action:close')"
        >
          Close
        </button>
      </template>
    </modal-template>
  </div>
</template>

<script>
// Import Template Components
import ModalTemplate from "@/components/templates/ModalTemplate.vue";

export default {
  components: {
    ModalTemplate,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    role: {
      type: String,
      default: "",
    },
    hospitalList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    config: {
      handler(val) {
        if (val.selectedHospital) {
          this.selectedHospital = val.selectedHospital;
        }

        if (val.selectedPhysician) {
          this.selectedPhysician = val.selectedPhysician;
        }

        if (val.physicianOpinionsList) {
          this.physicianOpinionsList = [...val.physicianOpinionsList];
        }

        if (val.physicianOpinionIds) {
          this.physicianOpinionIds = [...val.physicianOpinionIds];
        }

        if (val.availablePhysicianForOpinion) {
          this.availablePhysicianForOpinion = [
            ...val.availablePhysicianForOpinion,
          ];
        }
      },
      deep: true,
    },
    selectedHospital: {
      handler(val) {
        if (val) {
          this.$emit("action:fetch-physicians", val);
          this.$emit("action:set-selected-hospital", val);
        }
      },
    },
  },
  data: () => ({
    selectedHospital: null,
    selectedPhysician: null,
    physicianOpinionsList: [],
    physicianOpinionIds: [],
    availablePhysicianForOpinion: [],
  }),
};
</script>

<style></style>
