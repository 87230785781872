<template>
  <div id="physician">
    <list-template 
      v-bind="config" 
      @fetch:data="fetchData"
      @delete:confirm="deleteConfirm"
    />
  </div>
</template>

<script>
// mixins
import crudRequestMixin from '@/mixins/listPages/httpRequestMixins';
// components
import ListTemplate from "@/components/templates/ListTemplate.vue";

export default {
  name: 'PhysiciansList',
  mixins: [
    crudRequestMixin
  ],
  components: {
    ListTemplate,
  },
  data: () => {
    return {
      config: {
        loading: false,
        title: 'Treating Physicians',
        pagination: {},
        rows: [],
        columns: [
          {
            key: "firstName",
            label: "First Name",
          },
          {
            key: "lastName",
            label: "Last Name",
          },
          {
            key: "email",
            label: "Email",
          },
          {
            key: 'phoneNumber',
            label: "Phone Number"
          },
          {
            key: 'mobileNumber',
            label: 'Mobile Number'
          },
          {
            key: 'actions',
            label: 'Actions'
          }
        ],
        loadingText: 'Fetching Data...',
        emptyText: 'No data found',
        createFormName: "CreatePhysicians",
        createButtonText: "Add Physicians",
        editFormName: 'EditPhysicians',
        searchPlaceholder: 'Search by First Name',
        searchBy: 'firstName'
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleFetchListSuccess(response) {
      this.config.rows = response.data.content;
      this.config.pagination = response.data.pageable;
      this.config.pagination['totalRows'] = response.data.totalElements;
      this.config.pagination['totalPage'] = response.data.totalPages; 

       this.config.rows.forEach(el => {
        el['details'] = [
          { key: 'language', label: 'Language', value: el.language },
          { key: 'address', label: 'Address', value: el.address },
          { key: 'city', label: 'City', value: el.city },
          { key: 'postcode', label: 'Postcode', value: el.postcode }
        ];
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
#physician {
  height: 60vh !important;
}
</style>
