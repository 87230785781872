<template>
  <div id="hospital-list">
    <list-template 
      v-bind="config" 
      @fetch:data="fetchData"
      @delete:confirm="deleteConfirm"
    />
  </div>
</template>

<script>
// mixins
import crudRequestMixin from '@/mixins/listPages/httpRequestMixins';
// components
import ListTemplate from "@/components/templates/ListTemplate.vue";

export default {
  name: "HospitalList",
  mixins: [
    crudRequestMixin
  ],
  components: {
    ListTemplate,
  },
  data: () => {
    return {
      config: {
        loading: false,
        title: "Hospital Management",
        rows: [],
        pagination: {},
        columns: [
          {
            key: "name",
            label: "Name",
          },
          {
            key: "address",
            label: "Address",
          },
          {
            key: "city",
            label: "City",
          },
          {
            key: "postcode",
            label: "Postcode",
          },
          {
            key: "actions",
            label: "Actions",
          },
        ],
        loadingText: 'Fetching Data...',
        emptyText: 'No data found',
        createFormName: "CreateHospital",
        createButtonText: "Add Hospital",
        editFormName: 'EditHospital',
        searchPlaceholder: 'Search by name'
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleFetchListSuccess(response) {
      this.config.rows = response.data.content;
      this.config.pagination = response.data.pageable;
      this.config.pagination['totalRows'] = response.data.totalElements;
      this.config.pagination['totalPage'] = response.data.totalPages; 
    },
  }
};
</script>

<style>
#hospital-list {
  height: 60vh !important;
}
</style>
