<template>
  <modal-template v-bind="config" @modal:on-close="$emit('action:close')">
    <template #modal-body>
      <notification type="info" :message="modalInfo" />

      <b-form-group v-if="role === 'ADMIN'">
        <b-form-select
          v-model="selectedHospital"
          :options="hospitalList"
          type="text"
          name="hospital"
          placeholder="Select Hospital"
          size="sm"
        />
      </b-form-group>

      <b-form-group :description="alertEmptyPhysician">
        <b-form-select
          v-model="selectedPhysician"
          :options="physiciansList"
          :disabled="!selectedHospital && role === 'ADMIN'"
          type="text"
          name="physician"
          placeholder="Select Physician"
          size="sm"
        />
      </b-form-group>
      <span v-if="config.selectedPhysicianName">
        <small
          ><b>
            Currently selected physician: {{ config.selectedPhysicianName }}
          </b></small
        >
      </span>
    </template>

    <template #modal-footer-custom>
      <button
        v-if="config.state !== 'edit'"
        :disabled="!selectedPhysician"
        class="btn btn-sm btn-pompe-primary"
        @click.prevent="$emit('action:submit')"
      >
        Submit
      </button>

      <button
        v-else-if="config.state === 'edit'"
        :disabled="!selectedPhysician"
        class="btn btn-sm btn-pompe-primary"
        @click.prevent="$emit('action:update', selectedPhysician)"
      >
        Update
      </button>
    </template>
  </modal-template>
</template>

<script>
// Import Template Components
import ModalTemplate from "@/components/templates/ModalTemplate.vue";

export default {
  components: {
    ModalTemplate,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    role: {
      type: String,
      default: "",
    },
    hospitalList: {
      type: Array,
      default: () => [],
    },
    physiciansList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    alertEmptyPhysician() {
      if (this.selectedHospital && this.physiciansList.length === 1) {
        return "This hospital has no physician";
      }

      return null;
    },
    modalInfo() {
      if (this.config.state === "edit") {
        return "Please choose which Physician will be replacing the existing";
      }

      return "Please choose which Physician will be assigned to the Patient";
    },
  },
  data: () => ({
    selectedHospital: null,
    selectedPhysician: null,
  }),
  watch: {
    selectedHospital: {
      handler(val) {
        if (val) this.$emit("action:fetch-physicians", val);
      },
    },
    config: {
      handler(val) {
        if (!val.show) {
          this.selectedHospital = null;
        }
      },
      deep: true,
    },
    selectedPhysician: {
      handler(val) {
        if (val) this.$emit("update:selected-physician", val);
      },
    },
  },
};
</script>

<style></style>
